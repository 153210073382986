import React from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { PersonDto, PersonStatusDto } from '../../../api';
import personApi from '../../../data-access/person-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import useToast from '../../../ui/use-toast/use-toast';
import useDialog from '../../../util/use-dialog/use-dialog';
import PersonDeleteDialog from './person-delete-dialog';

export interface PersonDeleteButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  person: PersonDto;
  isDeletable: boolean;
  onSuccess?(): void;
}

const PersonDeleteButton = React.forwardRef<HTMLButtonElement, PersonDeleteButtonProps>(
  ({ children, person, isDeletable, onSuccess, onClick, disabled, ...props }, ref) => {
    const { t } = useTranslation('person');
    const [deleteDialogIsOpen, onDeleteDialogClose, openDeleteDialog] = useDialog<false>();

    const showDeleteSuccessToast = useToast({
      id: 'person-deletion-success-toast',
      status: 'success',
    });

    const showDeleteFailedToast = useToast({
      id: 'person-deletion-failed-toast',
      status: 'error',
    });

    const deletePerson = async (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(event);

      if (event.defaultPrevented) {
        return;
      }

      invariant(person.id != null, 'Missing person id');

      const checked = await openDeleteDialog();
      if (checked) {
        try {
          await personApi.updatePerson({ id: person.id, personDto: { ...person, status: PersonStatusDto.DELETED } }, {
            allowedErrorCodes: [409],
          } as RequestInit);

          showDeleteSuccessToast({
            title: t('action.delete.delete_success.title'),
            description: t('action.delete.delete_success.description', {
              firstName: person.firstName,
              surname: person.surname,
              personKey: person.personKey,
            }),
          });
          onSuccess?.();
        } catch (_) {
          showDeleteFailedToast({
            title: t('action.delete.delete_failed.title'),
            description: t('action.delete.delete_failed.description', {
              firstName: person.firstName,
              surname: person.surname,
              personKey: person.personKey,
            }),
          });
        }
      }
    };

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={deletePerson}
          disableReason={t('action.delete.delete_failed.description', {
            firstName: person.firstName,
            surname: person.surname,
            personKey: person.personKey,
          })}
          isDisabled={!isDeletable || disabled}
        >
          {children}
        </DisableableButton>
        <PersonDeleteDialog person={person} isOpen={deleteDialogIsOpen} onClose={onDeleteDialogClose} />
      </>
    );
  },
);

export default PersonDeleteButton;
