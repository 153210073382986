/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AllDuplicatesPageDto,
  CompanyPersonPageDto,
  CompanyReferenceDto,
  CreateCompanyPersonRelationRequestDto,
  DefaultEmailAddressDto,
  FailureDto,
  HistoryPageDto,
  HistoryPersonDto,
  ImportErrorDto,
  ImportSuccessDto,
  MergeFailureDto,
  MergePersonIdsDto,
  MergedPersonDto,
  PageableDto,
  PersonAnonymisationBlockerDto,
  PersonCardDto,
  PersonCommentDto,
  PersonCommentPageDto,
  PersonDto,
  PersonDuplicatesDto,
  PersonMergeConflictDto,
  PersonNameDto,
  PersonPageDto,
  PersonSectionDto,
  PersonStatusCheckDto,
  PossibleDuplicatesPageDto,
} from '../models/index';
import {
    AllDuplicatesPageDtoFromJSON,
    AllDuplicatesPageDtoToJSON,
    CompanyPersonPageDtoFromJSON,
    CompanyPersonPageDtoToJSON,
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoToJSON,
    CreateCompanyPersonRelationRequestDtoFromJSON,
    CreateCompanyPersonRelationRequestDtoToJSON,
    DefaultEmailAddressDtoFromJSON,
    DefaultEmailAddressDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    HistoryPersonDtoFromJSON,
    HistoryPersonDtoToJSON,
    ImportErrorDtoFromJSON,
    ImportErrorDtoToJSON,
    ImportSuccessDtoFromJSON,
    ImportSuccessDtoToJSON,
    MergeFailureDtoFromJSON,
    MergeFailureDtoToJSON,
    MergePersonIdsDtoFromJSON,
    MergePersonIdsDtoToJSON,
    MergedPersonDtoFromJSON,
    MergedPersonDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    PersonAnonymisationBlockerDtoFromJSON,
    PersonAnonymisationBlockerDtoToJSON,
    PersonCardDtoFromJSON,
    PersonCardDtoToJSON,
    PersonCommentDtoFromJSON,
    PersonCommentDtoToJSON,
    PersonCommentPageDtoFromJSON,
    PersonCommentPageDtoToJSON,
    PersonDtoFromJSON,
    PersonDtoToJSON,
    PersonDuplicatesDtoFromJSON,
    PersonDuplicatesDtoToJSON,
    PersonMergeConflictDtoFromJSON,
    PersonMergeConflictDtoToJSON,
    PersonNameDtoFromJSON,
    PersonNameDtoToJSON,
    PersonPageDtoFromJSON,
    PersonPageDtoToJSON,
    PersonSectionDtoFromJSON,
    PersonSectionDtoToJSON,
    PersonStatusCheckDtoFromJSON,
    PersonStatusCheckDtoToJSON,
    PossibleDuplicatesPageDtoFromJSON,
    PossibleDuplicatesPageDtoToJSON,
} from '../models/index';

export interface AnonymisePeopleRequest {
    requestBody: Array<string>;
}

export interface CanDeleteProfessionalAccountRequest {
    id: string;
}

export interface CheckPersonStatusRequest {
    personId: string;
}

export interface CompanyPersonRelationExistsRequest {
    companyId: string;
    personId: string;
}

export interface ConvertBasicToProfessionalAccountRequest {
    id: string;
    defaultEmailAddressDto: DefaultEmailAddressDto;
}

export interface ConvertProfessionalToBasicAccountRequest {
    id: string;
}

export interface CreateCompanyPersonRelationRequest {
    createCompanyPersonRelationRequestDto: CreateCompanyPersonRelationRequestDto;
}

export interface CreatePersonRequest {
    personDto: PersonDto;
}

export interface CreatePersonCommentRequest {
    personId: string;
    personCommentDto: PersonCommentDto;
}

export interface DeletePersonRequest {
    id: string;
}

export interface DeletePersonCommentRequest {
    personId: string;
    commentId: string;
}

export interface DeleteProfessionalAccountRequest {
    id: string;
}

export interface FetchPersonRequest {
    id: string;
}

export interface FetchPersonCardRequest {
    personId: string;
}

export interface FetchPersonCommentsRequest {
    personId: string;
    pageable?: PageableDto;
}

export interface FetchPersonCompaniesRequest {
    personId: string;
}

export interface FetchPersonHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface FetchPersonSectionsRequest {
    personId: string;
}

export interface FetchSlimPersonRequest {
    personId: string;
}

export interface GetAllPossibleDuplicatesRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface GetMergeConflictsRequest {
    mergePersonIdsDto: MergePersonIdsDto;
}

export interface GetPossibleDuplicatesRequest {
    fullName: PersonNameDto;
    stageName?: string;
    dateOfBirth?: Date;
    alternativeNames?: Array<string>;
}

export interface GetPossibleDuplicatesPageRequest {
    personId: string;
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface HasProfessionalAccountRequest {
    id: string;
}

export interface ImportPeopleRequest {
    file: Blob;
}

export interface MergePersonsRequest {
    mergedPersonDto: MergedPersonDto;
}

export interface NoDuplicateRequest {
    mergePersonIdsDto: MergePersonIdsDto;
}

export interface SearchAnonymizedPersonsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchDeletedPersonsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchPersonsRequest {
    q?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchPersonsByCompanyRequest {
    id: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdatePersonRequest {
    id: string;
    personDto: PersonDto;
}

export interface UpdatePersonCommentRequest {
    personId: string;
    commentId: string;
    personCommentDto: PersonCommentDto;
}

/**
 * 
 */
export class PersonApi extends runtime.BaseAPI {

    /**
     * Anonymise the people with the given person ids.
     */
    async anonymisePeopleRaw(requestParameters: AnonymisePeopleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PersonAnonymisationBlockerDto>>> {
        if (requestParameters['requestBody'] == null) {
            throw new runtime.RequiredError(
                'requestBody',
                'Required parameter "requestBody" was null or undefined when calling anonymisePeople().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/anonymise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['requestBody'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonAnonymisationBlockerDtoFromJSON));
    }

    /**
     * Anonymise the people with the given person ids.
     */
    async anonymisePeople(requestParameters: AnonymisePeopleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PersonAnonymisationBlockerDto>> {
        const response = await this.anonymisePeopleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async canDeleteProfessionalAccountRaw(requestParameters: CanDeleteProfessionalAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling canDeleteProfessionalAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/can-delete-professional/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async canDeleteProfessionalAccount(requestParameters: CanDeleteProfessionalAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.canDeleteProfessionalAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the current status of the person.
     */
    async checkPersonStatusRaw(requestParameters: CheckPersonStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonStatusCheckDto>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling checkPersonStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{personId}/check-status`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonStatusCheckDtoFromJSON(jsonValue));
    }

    /**
     * Get the current status of the person.
     */
    async checkPersonStatus(requestParameters: CheckPersonStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonStatusCheckDto> {
        const response = await this.checkPersonStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get whether the given person-company relation already exists
     */
    async companyPersonRelationExistsRaw(requestParameters: CompanyPersonRelationExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling companyPersonRelationExists().'
            );
        }

        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling companyPersonRelationExists().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyId'] != null) {
            queryParameters['companyId'] = requestParameters['companyId'];
        }

        if (requestParameters['personId'] != null) {
            queryParameters['personId'] = requestParameters['personId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/company-person-relation-exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get whether the given person-company relation already exists
     */
    async companyPersonRelationExists(requestParameters: CompanyPersonRelationExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.companyPersonRelationExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Convert the basic account to a professional account.
     */
    async convertBasicToProfessionalAccountRaw(requestParameters: ConvertBasicToProfessionalAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling convertBasicToProfessionalAccount().'
            );
        }

        if (requestParameters['defaultEmailAddressDto'] == null) {
            throw new runtime.RequiredError(
                'defaultEmailAddressDto',
                'Required parameter "defaultEmailAddressDto" was null or undefined when calling convertBasicToProfessionalAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/upgrade/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DefaultEmailAddressDtoToJSON(requestParameters['defaultEmailAddressDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Convert the basic account to a professional account.
     */
    async convertBasicToProfessionalAccount(requestParameters: ConvertBasicToProfessionalAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.convertBasicToProfessionalAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Convert the professional account to a basic account.
     */
    async convertProfessionalToBasicAccountRaw(requestParameters: ConvertProfessionalToBasicAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling convertProfessionalToBasicAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/downgrade/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Convert the professional account to a basic account.
     */
    async convertProfessionalToBasicAccount(requestParameters: ConvertProfessionalToBasicAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.convertProfessionalToBasicAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Create Company-Person relation.
     */
    async createCompanyPersonRelationRaw(requestParameters: CreateCompanyPersonRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createCompanyPersonRelationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'createCompanyPersonRelationRequestDto',
                'Required parameter "createCompanyPersonRelationRequestDto" was null or undefined when calling createCompanyPersonRelation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/create-company-relation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyPersonRelationRequestDtoToJSON(requestParameters['createCompanyPersonRelationRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Company-Person relation.
     */
    async createCompanyPersonRelation(requestParameters: CreateCompanyPersonRelationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createCompanyPersonRelationRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new person.
     */
    async createPersonRaw(requestParameters: CreatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonDto>> {
        if (requestParameters['personDto'] == null) {
            throw new runtime.RequiredError(
                'personDto',
                'Required parameter "personDto" was null or undefined when calling createPerson().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonDtoToJSON(requestParameters['personDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDtoFromJSON(jsonValue));
    }

    /**
     * Create a new person.
     */
    async createPerson(requestParameters: CreatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonDto> {
        const response = await this.createPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new comment.
     */
    async createPersonCommentRaw(requestParameters: CreatePersonCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonCommentDto>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling createPersonComment().'
            );
        }

        if (requestParameters['personCommentDto'] == null) {
            throw new runtime.RequiredError(
                'personCommentDto',
                'Required parameter "personCommentDto" was null or undefined when calling createPersonComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{personId}/comments`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonCommentDtoToJSON(requestParameters['personCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonCommentDtoFromJSON(jsonValue));
    }

    /**
     * Create a new comment.
     */
    async createPersonComment(requestParameters: CreatePersonCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonCommentDto> {
        const response = await this.createPersonCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the person.
     */
    async deletePersonRaw(requestParameters: DeletePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deletePerson().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the person.
     */
    async deletePerson(requestParameters: DeletePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePersonRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the comment.
     */
    async deletePersonCommentRaw(requestParameters: DeletePersonCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling deletePersonComment().'
            );
        }

        if (requestParameters['commentId'] == null) {
            throw new runtime.RequiredError(
                'commentId',
                'Required parameter "commentId" was null or undefined when calling deletePersonComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{personId}/comments/{commentId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters['commentId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the comment.
     */
    async deletePersonComment(requestParameters: DeletePersonCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePersonCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete the professional account.
     */
    async deleteProfessionalAccountRaw(requestParameters: DeleteProfessionalAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteProfessionalAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/delete-professional/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the professional account.
     */
    async deleteProfessionalAccount(requestParameters: DeleteProfessionalAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProfessionalAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Get the person business object.
     */
    async fetchPersonRaw(requestParameters: FetchPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchPerson().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDtoFromJSON(jsonValue));
    }

    /**
     * Get the person business object.
     */
    async fetchPerson(requestParameters: FetchPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonDto> {
        const response = await this.fetchPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the person business card. Will be shown while hovering over a person reference.
     */
    async fetchPersonCardRaw(requestParameters: FetchPersonCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonCardDto>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling fetchPersonCard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{personId}/card`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonCardDtoFromJSON(jsonValue));
    }

    /**
     * Get the person business card. Will be shown while hovering over a person reference.
     */
    async fetchPersonCard(requestParameters: FetchPersonCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonCardDto> {
        const response = await this.fetchPersonCardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the comments for a person
     */
    async fetchPersonCommentsRaw(requestParameters: FetchPersonCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonCommentPageDto>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling fetchPersonComments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{personId}/comments`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonCommentPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the comments for a person
     */
    async fetchPersonComments(requestParameters: FetchPersonCommentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonCommentPageDto> {
        const response = await this.fetchPersonCommentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get companies that given person has occupation in
     */
    async fetchPersonCompaniesRaw(requestParameters: FetchPersonCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyReferenceDto>>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling fetchPersonCompanies().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{personId}/companies`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyReferenceDtoFromJSON));
    }

    /**
     * Get companies that given person has occupation in
     */
    async fetchPersonCompanies(requestParameters: FetchPersonCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyReferenceDto>> {
        const response = await this.fetchPersonCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the person.
     */
    async fetchPersonHistoryRaw(requestParameters: FetchPersonHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchPersonHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the history of the person.
     */
    async fetchPersonHistory(requestParameters: FetchPersonHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.fetchPersonHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get sections with job titles that given employee belongs to
     */
    async fetchPersonSectionsRaw(requestParameters: FetchPersonSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PersonSectionDto>>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling fetchPersonSections().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{personId}/sections`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonSectionDtoFromJSON));
    }

    /**
     * Get sections with job titles that given employee belongs to
     */
    async fetchPersonSections(requestParameters: FetchPersonSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PersonSectionDto>> {
        const response = await this.fetchPersonSectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get slim version of a person
     */
    async fetchSlimPersonRaw(requestParameters: FetchSlimPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPersonDto>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling fetchSlimPerson().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{personId}/slim`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPersonDtoFromJSON(jsonValue));
    }

    /**
     * Get slim version of a person
     */
    async fetchSlimPerson(requestParameters: FetchSlimPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPersonDto> {
        const response = await this.fetchSlimPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all possible person duplicates sorted and paginated.
     */
    async getAllPossibleDuplicatesRaw(requestParameters: GetAllPossibleDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AllDuplicatesPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/all-duplicates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllDuplicatesPageDtoFromJSON(jsonValue));
    }

    /**
     * Get all possible person duplicates sorted and paginated.
     */
    async getAllPossibleDuplicates(requestParameters: GetAllPossibleDuplicatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AllDuplicatesPageDto> {
        const response = await this.getAllPossibleDuplicatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * evaluates if there are conflicts prohibiting the merge
     */
    async getMergeConflictsRaw(requestParameters: GetMergeConflictsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PersonMergeConflictDto>>> {
        if (requestParameters['mergePersonIdsDto'] == null) {
            throw new runtime.RequiredError(
                'mergePersonIdsDto',
                'Required parameter "mergePersonIdsDto" was null or undefined when calling getMergeConflicts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/merge-conflicts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergePersonIdsDtoToJSON(requestParameters['mergePersonIdsDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PersonMergeConflictDtoFromJSON));
    }

    /**
     * evaluates if there are conflicts prohibiting the merge
     */
    async getMergeConflicts(requestParameters: GetMergeConflictsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PersonMergeConflictDto>> {
        const response = await this.getMergeConflictsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all possible duplicates of the person using firstname, surname, stage name, alternative names and date of birth.
     */
    async getPossibleDuplicatesRaw(requestParameters: GetPossibleDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonDuplicatesDto>> {
        if (requestParameters['fullName'] == null) {
            throw new runtime.RequiredError(
                'fullName',
                'Required parameter "fullName" was null or undefined when calling getPossibleDuplicates().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['fullName'] != null) {
            queryParameters['fullName'] = requestParameters['fullName'];
        }

        if (requestParameters['stageName'] != null) {
            queryParameters['stageName'] = requestParameters['stageName'];
        }

        if (requestParameters['dateOfBirth'] != null) {
            queryParameters['dateOfBirth'] = (requestParameters['dateOfBirth'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['alternativeNames'] != null) {
            queryParameters['alternativeNames'] = requestParameters['alternativeNames']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/possible-duplicates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDuplicatesDtoFromJSON(jsonValue));
    }

    /**
     * Get all possible duplicates of the person using firstname, surname, stage name, alternative names and date of birth.
     */
    async getPossibleDuplicates(requestParameters: GetPossibleDuplicatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonDuplicatesDto> {
        const response = await this.getPossibleDuplicatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all possible duplicates of the person sorted and paginated.
     */
    async getPossibleDuplicatesPageRaw(requestParameters: GetPossibleDuplicatesPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PossibleDuplicatesPageDto>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling getPossibleDuplicatesPage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{personId}/possible-duplicates-page`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PossibleDuplicatesPageDtoFromJSON(jsonValue));
    }

    /**
     * Get all possible duplicates of the person sorted and paginated.
     */
    async getPossibleDuplicatesPage(requestParameters: GetPossibleDuplicatesPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PossibleDuplicatesPageDto> {
        const response = await this.getPossibleDuplicatesPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async hasProfessionalAccountRaw(requestParameters: HasProfessionalAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling hasProfessionalAccount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/has-professional-account/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async hasProfessionalAccount(requestParameters: HasProfessionalAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.hasProfessionalAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Import people of the CSV
     */
    async importPeopleRaw(requestParameters: ImportPeopleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportSuccessDto>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling importPeople().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/persons/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportSuccessDtoFromJSON(jsonValue));
    }

    /**
     * Import people of the CSV
     */
    async importPeople(requestParameters: ImportPeopleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportSuccessDto> {
        const response = await this.importPeopleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Merge two persons and create a new one.
     */
    async mergePersonsRaw(requestParameters: MergePersonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonDto>> {
        if (requestParameters['mergedPersonDto'] == null) {
            throw new runtime.RequiredError(
                'mergedPersonDto',
                'Required parameter "mergedPersonDto" was null or undefined when calling mergePersons().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/merge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergedPersonDtoToJSON(requestParameters['mergedPersonDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDtoFromJSON(jsonValue));
    }

    /**
     * Merge two persons and create a new one.
     */
    async mergePersons(requestParameters: MergePersonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonDto> {
        const response = await this.mergePersonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark two persons as no duplicates
     */
    async noDuplicateRaw(requestParameters: NoDuplicateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['mergePersonIdsDto'] == null) {
            throw new runtime.RequiredError(
                'mergePersonIdsDto',
                'Required parameter "mergePersonIdsDto" was null or undefined when calling noDuplicate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/no-duplicate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergePersonIdsDtoToJSON(requestParameters['mergePersonIdsDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark two persons as no duplicates
     */
    async noDuplicate(requestParameters: NoDuplicateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.noDuplicateRaw(requestParameters, initOverrides);
    }

    /**
     * Search for persons.
     */
    async searchAnonymizedPersonsRaw(requestParameters: SearchAnonymizedPersonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/to-anonymise`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for persons.
     */
    async searchAnonymizedPersons(requestParameters: SearchAnonymizedPersonsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonPageDto> {
        const response = await this.searchAnonymizedPersonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for persons.
     */
    async searchDeletedPersonsRaw(requestParameters: SearchDeletedPersonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/to-delete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for persons.
     */
    async searchDeletedPersons(requestParameters: SearchDeletedPersonsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonPageDto> {
        const response = await this.searchDeletedPersonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for persons.
     */
    async searchPersonsRaw(requestParameters: SearchPersonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for persons.
     */
    async searchPersons(requestParameters: SearchPersonsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonPageDto> {
        const response = await this.searchPersonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for persons by company.
     */
    async searchPersonsByCompanyRaw(requestParameters: SearchPersonsByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyPersonPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling searchPersonsByCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{id}/company-persons`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyPersonPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for persons by company.
     */
    async searchPersonsByCompany(requestParameters: SearchPersonsByCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyPersonPageDto> {
        const response = await this.searchPersonsByCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new person.
     */
    async templatePersonRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new person.
     */
    async templatePerson(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonDto> {
        const response = await this.templatePersonRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing person.
     */
    async updatePersonRaw(requestParameters: UpdatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePerson().'
            );
        }

        if (requestParameters['personDto'] == null) {
            throw new runtime.RequiredError(
                'personDto',
                'Required parameter "personDto" was null or undefined when calling updatePerson().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PersonDtoToJSON(requestParameters['personDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing person.
     */
    async updatePerson(requestParameters: UpdatePersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonDto> {
        const response = await this.updatePersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing comment.
     */
    async updatePersonCommentRaw(requestParameters: UpdatePersonCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PersonCommentDto>> {
        if (requestParameters['personId'] == null) {
            throw new runtime.RequiredError(
                'personId',
                'Required parameter "personId" was null or undefined when calling updatePersonComment().'
            );
        }

        if (requestParameters['commentId'] == null) {
            throw new runtime.RequiredError(
                'commentId',
                'Required parameter "commentId" was null or undefined when calling updatePersonComment().'
            );
        }

        if (requestParameters['personCommentDto'] == null) {
            throw new runtime.RequiredError(
                'personCommentDto',
                'Required parameter "personCommentDto" was null or undefined when calling updatePersonComment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/persons/{personId}/comments/{commentId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters['personId']))).replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters['commentId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PersonCommentDtoToJSON(requestParameters['personCommentDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonCommentDtoFromJSON(jsonValue));
    }

    /**
     * Update the existing comment.
     */
    async updatePersonComment(requestParameters: UpdatePersonCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PersonCommentDto> {
        const response = await this.updatePersonCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
