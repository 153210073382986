/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileMetadataDto } from './FileMetadataDto';
import {
    FileMetadataDtoFromJSON,
    FileMetadataDtoFromJSONTyped,
    FileMetadataDtoToJSON,
} from './FileMetadataDto';
import type { PersonMarkerDto } from './PersonMarkerDto';
import {
    PersonMarkerDtoFromJSON,
    PersonMarkerDtoFromJSONTyped,
    PersonMarkerDtoToJSON,
} from './PersonMarkerDto';
import type { PersonStatusDto } from './PersonStatusDto';
import {
    PersonStatusDtoFromJSON,
    PersonStatusDtoFromJSONTyped,
    PersonStatusDtoToJSON,
} from './PersonStatusDto';
import type { PhoneNumberDto } from './PhoneNumberDto';
import {
    PhoneNumberDtoFromJSON,
    PhoneNumberDtoFromJSONTyped,
    PhoneNumberDtoToJSON,
} from './PhoneNumberDto';

/**
 * Reference of a person as a key player
 * @export
 * @interface KeyPlayerPersonDto
 */
export interface KeyPlayerPersonDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof KeyPlayerPersonDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KeyPlayerPersonDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof KeyPlayerPersonDto
     */
    surname: string;
    /**
     * The unique key for a person starting with "P".
     * @type {string}
     * @memberof KeyPlayerPersonDto
     */
    personKey: string;
    /**
     * List of phone numbers
     * @type {Array<PhoneNumberDto>}
     * @memberof KeyPlayerPersonDto
     */
    phoneNumbers?: Array<PhoneNumberDto>;
    /**
     * List of contact email addresses (non-confidential)
     * @type {Array<string>}
     * @memberof KeyPlayerPersonDto
     */
    emailAddresses?: Array<string>;
    /**
     * The stage name of the person
     * @type {string}
     * @memberof KeyPlayerPersonDto
     */
    stageName?: string;
    /**
     * Language to contact the person
     * @type {string}
     * @memberof KeyPlayerPersonDto
     */
    contactLanguage?: KeyPlayerPersonDtoContactLanguageEnum;
    /**
     * List of markers for the person
     * @type {Array<PersonMarkerDto>}
     * @memberof KeyPlayerPersonDto
     */
    markers?: Array<PersonMarkerDto>;
    /**
     * A list of person types (eg. staff member)
     * @type {Array<string>}
     * @memberof KeyPlayerPersonDto
     */
    types?: Array<KeyPlayerPersonDtoTypesEnum>;
    /**
     * 
     * @type {PersonStatusDto}
     * @memberof KeyPlayerPersonDto
     */
    status?: PersonStatusDto;
    /**
     * 
     * @type {FileMetadataDto}
     * @memberof KeyPlayerPersonDto
     */
    photoMetadata?: FileMetadataDto;
}


/**
 * @export
 */
export const KeyPlayerPersonDtoContactLanguageEnum = {
    GERMAN: 'GERMAN',
    ENGLISH: 'ENGLISH'
} as const;
export type KeyPlayerPersonDtoContactLanguageEnum = typeof KeyPlayerPersonDtoContactLanguageEnum[keyof typeof KeyPlayerPersonDtoContactLanguageEnum];

/**
 * @export
 */
export const KeyPlayerPersonDtoTypesEnum = {
    STAFF: 'STAFF',
    SERVICE_PROVIDER: 'SERVICE_PROVIDER',
    PROFESSIONAL: 'PROFESSIONAL'
} as const;
export type KeyPlayerPersonDtoTypesEnum = typeof KeyPlayerPersonDtoTypesEnum[keyof typeof KeyPlayerPersonDtoTypesEnum];


/**
 * Check if a given object implements the KeyPlayerPersonDto interface.
 */
export function instanceOfKeyPlayerPersonDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('personKey' in value)) return false;
    return true;
}

export function KeyPlayerPersonDtoFromJSON(json: any): KeyPlayerPersonDto {
    return KeyPlayerPersonDtoFromJSONTyped(json, false);
}

export function KeyPlayerPersonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyPlayerPersonDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'surname': json['surname'],
        'personKey': json['personKey'],
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : ((json['phoneNumbers'] as Array<any>).map(PhoneNumberDtoFromJSON)),
        'emailAddresses': json['emailAddresses'] == null ? undefined : json['emailAddresses'],
        'stageName': json['stageName'] == null ? undefined : json['stageName'],
        'contactLanguage': json['contactLanguage'] == null ? undefined : json['contactLanguage'],
        'markers': json['markers'] == null ? undefined : ((json['markers'] as Array<any>).map(PersonMarkerDtoFromJSON)),
        'types': json['types'] == null ? undefined : json['types'],
        'status': json['status'] == null ? undefined : PersonStatusDtoFromJSON(json['status']),
        'photoMetadata': json['photoMetadata'] == null ? undefined : FileMetadataDtoFromJSON(json['photoMetadata']),
    };
}

export function KeyPlayerPersonDtoToJSON(value?: KeyPlayerPersonDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'personKey': value['personKey'],
        'phoneNumbers': value['phoneNumbers'] == null ? undefined : ((value['phoneNumbers'] as Array<any>).map(PhoneNumberDtoToJSON)),
        'emailAddresses': value['emailAddresses'],
        'stageName': value['stageName'],
        'contactLanguage': value['contactLanguage'],
        'markers': value['markers'] == null ? undefined : ((value['markers'] as Array<any>).map(PersonMarkerDtoToJSON)),
        'types': value['types'],
        'status': PersonStatusDtoToJSON(value['status']),
        'photoMetadata': FileMetadataDtoToJSON(value['photoMetadata']),
    };
}

